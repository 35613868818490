import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { App } from '../_class/app';

import { IChangePassword, emptyChangePassword } from '../_interface/change-password-interface';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.scss'
})
export class ChangePasswordComponent {
  changePassword: IChangePassword = emptyChangePassword();
  token: string = "";

  get placeholderText(): string {
    let ret = "";
    return ret;
  }

  get saveIcon(): string {
    let ret = "key";
    return ret;
  }

  get saveText(): string {
    let ret = "Change password";
    return ret;
  }

  get titleIcon(): string {
    let ret = "key";
    return ret;
  }

  get titleText(): string {
    let ret = "Change password";
    return ret;
  }

  get showPlaceholder(): boolean {
    let show = false;
    return show;
  }

  constructor(public app: App, private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    // URL-Parameter ermitteln
    let params = this.route.snapshot.queryParams;
    let param = "";

    // Werte aus Parametern übernehmen
    param = "token";
    if (params[param]) {
      this.token = params[param];
    }
  }

  async save() {
    // Speichern
    let token = this.token ? this.token : this.app.accessToken;
    let ret = await this.app.changePassword(token, this.changePassword);

    if (ret.ok) {
      if (ret.msg) {
        this.app.showInfo(ret.msg);
        this.app.goBack();
      }
    } else {
      if (ret.msg) {
        this.app.showError(ret.msg);
      }

      return;
    }
  }
}
