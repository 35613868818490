<div class="main-content">
    <div class="top">
        <mat-card class="top-card" appearance="outlined">
            <mat-card-header class="back-color-secondary">
                <mat-card-title>
                    <mat-icon>support_agent</mat-icon>
                    <span style="padding-left: 12px">Support Packages</span>
                </mat-card-title>
                <mat-card-subtitle>
                    <span>
                        Please send an email to <a href="mailto:sales@micsto.com">sales&#64;micsto.com</a> if you would
                        like to purchase a support package.
                    </span>
                </mat-card-subtitle>
            </mat-card-header>

            <mat-card-actions class="back-color-secondary">
                <mat-form-field appearance="fill" subscriptSizing="dynamic">
                    <mat-label>Extension</mat-label>
                    <mat-select #extensionFilter [(value)]=selectedExtensionId
                        (selectionChange)="onSelectedExtensionChange($event.value)">
                        <mat-option [value]="extensionIdAll">(All extensions)</mat-option>
                        <mat-option *ngFor="let ext of extensions" [value]="ext.id">{{ext.name}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" subscriptSizing="dynamic">
                    <mat-label>Package component</mat-label>
                    <mat-select #extensionFilter [(value)]=selectedComponent
                      (selectionChange)="onSelectedComponentChange($event.value)">
                      <mat-option [value]="''">(Doesn't matter)</mat-option>
                      <mat-option *ngFor="let c of components" [value]="c.value">{{c.text}}</mat-option>
                    </mat-select>
                  </mat-form-field>
            </mat-card-actions>
        </mat-card>
    </div>

    <div class="main card-container">
        <div *ngFor="let item of items">
            <app-support-package [supportPackage]=item></app-support-package>
        </div>
    </div>
</div>