import { Component } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';


import { App } from '../_class/app';
import { IApiReturn } from '../_interface/api-return-interface';

@Component({
  selector: 'app-mail-validation',
  templateUrl: './mail-validation.component.html',
  styleUrl: './mail-validation.component.scss'
})
export class MailValidationComponent {
  validationResult: IApiReturn = {} as IApiReturn;

  // Konstruktor
  constructor(
    private app: App,
    private route: ActivatedRoute){

  }
  // Initialisierung
  ngOnInit(): void {
    // Ergebnis der Validierung
    this.validationResult = this.route.snapshot.data['validation_result'];
  }
}
