<div class="main-content">
    <div class="top">
        <mat-card class="top-card" appearance="outlined">
            <mat-card-header class="back-color-secondary">
                <mat-card-title>
                    <mat-icon>extension</mat-icon>
                    <span style="padding-left: 12px">Extensions</span>
                </mat-card-title>
            </mat-card-header>
        </mat-card>
    </div>

    <div class="main card-container">
        <div *ngFor="let extension of extensions">
            <app-extension [extension]=extension></app-extension>
        </div>
    </div>
</div>