import { Component, Input, OnInit } from '@angular/core';
import { ISupportPackage } from '../_interface/support-package-interface';

@Component({
  selector: 'app-support-package',
  templateUrl: './support-package.component.html',
  styleUrls: ['./support-package.component.scss']
})
export class SupportPackageComponent implements OnInit{
  @Input() supportPackage: ISupportPackage;

  constructor(){
    this.supportPackage = {} as ISupportPackage;
  }

  ngOnInit(): void {
  }
}
