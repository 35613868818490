<mat-card appearance="outlined">
    <mat-card-header>
        <mat-card-title>{{extension.name}}</mat-card-title>
        <mat-card-subtitle>Current Version: {{ extension.currentVersion }}</mat-card-subtitle>
    </mat-card-header>
    
    <mat-card-content>
        <p>{{ extension.shortDescription }}</p>
    </mat-card-content>

    <mat-card-actions>
        <a mat-stroked-button [routerLink]="'/downloads'" [queryParams]="{ext_id:extension.id}" color="primary">
            <mat-icon>
                download
            </mat-icon>
            Download
        </a>

        <a mat-stroked-button [routerLink]="'/support-packages'" [queryParams]="{ext_id:extension.id}" color="primary">
            <mat-icon>
                support_agent
            </mat-icon>
            Get premium support
        </a>

        <a *ngIf="extension.documentationURL" mat-stroked-button [href]="extension.documentationURL" [target]="'_blank'"
            color="primary">
            <mat-icon>
                help
            </mat-icon>
            View documentation
        </a>
    </mat-card-actions>
</mat-card>