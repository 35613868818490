// Import Angular
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { inject } from '@angular/core';

// Import Klassen
import { App } from '../_class/app';

export const authGuard: CanActivateFn = (route, state) => {
  let ret: boolean | UrlTree = false;

  let app = inject(App);
  let path = route.url[0].path;
  let urlToken = "";
  switch (path) {
    case 'change-password':
      urlToken = route.queryParams["token"];
      if (urlToken || app.accessToken) {
        ret = true;
      } else {
        ret = inject(Router).createUrlTree(['/']);
      }
      break;

    case 'login':
      if (!app.accessToken) {
        ret = true;
      } else {
        ret = inject(Router).createUrlTree(['/']);
      }
      break;

    case 'mail-validation':
      urlToken = route.queryParams["token"];
      if (urlToken) {
        ret = true;
      } else {
        ret = inject(Router).createUrlTree(['/']);
      }
      break;

    case 'register':
      if (!app.accessToken) {
        ret = true;
      } else {
        ret = inject(Router).createUrlTree(['/']);
      }
      break;

    case 'reset-password':
      urlToken = route.queryParams["token"];
      if (!(app.accessToken || urlToken)) {
        ret = true;
      } else {
        ret = inject(Router).createUrlTree(['/']);
      }
      break;

    case 'user-account':
      if (app.accessToken) {
        ret = true;
      } else {
        ret = inject(Router).createUrlTree(['/']);
      }
      break;
  }

  return ret;
};
