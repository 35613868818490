<mat-card appearance="outlined">
    <mat-card-header>
        <mat-card-title>{{supportPackage.name}}</mat-card-title>
        <mat-card-subtitle>{{ supportPackage.price | currency }}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <span>Type: {{supportPackage.type}}</span>
        <div *ngIf="supportPackage.term">
            <span>Term: {{supportPackage.term}}</span>
        </div>
    </mat-card-content>
</mat-card>