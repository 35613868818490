import { Component, Input, OnInit } from '@angular/core';
import { IExtension } from '../_interface/extension-interface';

@Component({
  selector: 'app-extension',
  templateUrl: './extension.component.html',
  styleUrls: ['./extension.component.scss']
})
export class ExtensionComponent implements OnInit{
  @Input() extension: IExtension;

  constructor(){
      this.extension = {} as IExtension;
  }

  ngOnInit(): void {
    
  }
}
