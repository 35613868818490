<div class="main-content">
  <div class="top">
    <mat-card class="top-card" appearance="outlined">
      <mat-card-header class="back-color-secondary">
        <mat-card-title>
          <mat-icon>restart_alt</mat-icon>
          <span style="padding-left: 12px">Reset password</span>
        </mat-card-title>
      </mat-card-header>
    </mat-card>
  </div>

  <div class="main">
    <form #form="ngForm" (ngSubmit)="reset()">
      <div class="field-container-vert">
        <mat-form-field appearance="fill" subscriptSizing="dynamic" [hideRequiredMarker]="!!resetPassword.email">
          <mat-label>E-mail</mat-label>
          <input required email matInput [(ngModel)]="resetPassword.email" name="email" type="email" autocomplete="email">
        </mat-form-field>

        <div class="container-horz-hcenter-vcenter">
          <button mat-stroked-button color="primary" class="button-save" type="submit">
            <mat-icon>restart_alt</mat-icon>
            Reset password
          </button>
        </div>
      </div>
    </form>
  </div>
</div>