<div class="main-content">
  <div class="top">
    <mat-card class="top-card" appearance="outlined">
      <mat-card-header class="back-color-secondary">
        <mat-card-title>
          <mat-icon>download</mat-icon>
          <span style="padding-left: 12px">Downloads</span>
        </mat-card-title>
      </mat-card-header>

      <mat-card-actions class="back-color-secondary">
        <mat-form-field appearance="fill" subscriptSizing="dynamic">
          <mat-label>Extension</mat-label>
          <mat-select #extensionFilter [(value)]=selectedExtensionId
            (selectionChange)="onSelectedExtensionChange($event.value)">
            <mat-option [value]="0">(All extensions)</mat-option>
            <mat-option *ngFor="let ext of extensions" [value]="ext.id">{{ext.name}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" subscriptSizing="dynamic">
          <mat-label>Team Developer Version</mat-label>
          <mat-select #extensionFilter [(value)]=selectedTdVersion
            (selectionChange)="onSelectedTdVersionChange($event.value)">
            <mat-option [value]="0">(All versions)</mat-option>
            <mat-option *ngFor="let ver of tdVersions" [value]="ver.id">{{ver.version}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" subscriptSizing="dynamic">
          <mat-label>Platform</mat-label>
          <mat-select #extensionFilter [(value)]=selectedPlatform
            (selectionChange)="onSelectedPlatformChange($event.value)">
            <mat-option [value]="''">(All platforms)</mat-option>
            <mat-option *ngFor="let pf of platforms" [value]="pf.value">{{pf.text}}</mat-option>
          </mat-select>
        </mat-form-field>
      </mat-card-actions>
    </mat-card>
  </div>

  <div class="main card-container">
    <div *ngFor="let item of items">
      <app-download [download]=item></app-download>
    </div>

    <div *ngIf="!items.length">
      <span>No downloads available</span>
    </div>
  </div>
</div>