<div class="content">
  <div class="top">
    <app-site-toolbar></app-site-toolbar>
  </div>
  <div class="main">
    <router-outlet></router-outlet>
  </div>
  <!-- <div class="bottom">
    <app-site-footer></app-site-footer>
  </div> -->
</div>