import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { AppComponent } from './app.component';
import { SiteToolbarComponent } from './site-toolbar/site-toolbar.component';
import { ExtensionComponent } from './extension/extension.component';
import { ExtensionsComponent } from './extensions/extensions.component';
import { SiteFooterComponent } from './site-footer/site-footer.component';
import { SupportPackageComponent } from './support-package/support-package.component';
import { SupportPackagesComponent } from './support-packages/support-packages.component';
import { LegalDisclosureComponent } from './legal-disclosure/legal-disclosure.component';
import { DownloadsComponent } from './downloads/downloads.component';
import { DownloadComponent } from './download/download.component';
import { LoginComponent } from './login/login.component';
import { UserAccountComponent } from './user-account/user-account.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

import { App, downloadsResolver, extensionsResolver, extSuppPackResolver, loggedInUserResolver, supportPackagesResolver, tdVersionsResolver, userSupportPackagesResolver, validateMailResolver } from './_class/app';

import { DEFAULT_CURRENCY_CODE } from '@angular/core';
import { MailValidationComponent } from './mail-validation/mail-validation.component';

// Import Funktionen
import { authGuard } from './authGuard/auth.guard';

const appRoutes: Routes = [    
    {
        path: 'change-password',
        component: ChangePasswordComponent,
        canActivate: [authGuard]
    },
    {
        path: 'downloads',
        component: DownloadsComponent,
        resolve: {
            downloads: downloadsResolver,
            extensions: extensionsResolver,
            tdversions: tdVersionsResolver
        }
    },
    {
        path: 'extensions',
        component: ExtensionsComponent,
        resolve: {
            extensions: extensionsResolver
        }        
    },
    {
        path: 'legal_disclosure',
        component: LegalDisclosureComponent
    },
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [authGuard]
    },
    {
        path: 'mail-validation',
        component: MailValidationComponent,
        resolve: {
            validation_result: validateMailResolver
        },
        canActivate: [authGuard]
    },
    {
        path: 'register',
        component: UserAccountComponent,
        canActivate: [authGuard]
    },
    {
        path: 'reset-password',
        component: ResetPasswordComponent,
        canActivate: [authGuard]
    },
    {
        path: 'support-packages',
        component: SupportPackagesComponent,
        resolve: {
            supportpackages: supportPackagesResolver,
            extensions: extensionsResolver,
            extsupppack: extSuppPackResolver
        }
    },
    {
        path: 'user-account',
        component: UserAccountComponent,
        resolve: {
            loggedinuser: loggedInUserResolver,
            supportpackages: userSupportPackagesResolver
        },
        canActivate: [authGuard]
    },
    { path: '', redirectTo: '/extensions', pathMatch: 'full' }
]

@NgModule({
    declarations: [
        AppComponent,
        SiteToolbarComponent,
        ExtensionComponent,
        ExtensionsComponent,
        SiteFooterComponent,
        SupportPackageComponent,
        SupportPackagesComponent,
        LegalDisclosureComponent,
        DownloadComponent,
        DownloadsComponent,
        LoginComponent,
        UserAccountComponent,
        MailValidationComponent,
        ChangePasswordComponent,
        ResetPasswordComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatToolbarModule,
        MatButtonModule,
        MatCardModule,
        MatIconModule,
        MatDividerModule,
        MatGridListModule,
        MatMenuModule,
        MatFormFieldModule,
        MatSelectModule,
        MatTooltipModule,
        FormsModule,
        MatInputModule,
        MatSnackBarModule,
        RouterModule.forRoot(appRoutes, { enableTracing: false } // <-- debugging purposes only
        )], providers: [
            App,
            {
                provide: DEFAULT_CURRENCY_CODE,
                useValue: 'EUR'
            },
            provideHttpClient(withInterceptorsFromDi())
        ]
})
export class AppModule { }
