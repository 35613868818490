import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { ISupportPackage } from '../_interface/support-package-interface';
import { IExtension } from '../_interface/extension-interface';
import { IExtSuppPack } from '../_interface/ext_supp_pack-interface';
import { IListItem } from '../_interface/list-item-interface';

@Component({
  selector: 'app-support-packages',
  templateUrl: './support-packages.component.html',
  styleUrls: ['./support-packages.component.scss']
})
export class SupportPackagesComponent implements OnInit {  
  components: Array<IListItem> = [
    {value: "sourcecode", text: "Sourcecode"},
    {value: "premium_support", text: "Premium Support"},
    {value: "both", text: "Both"}
  ];
  currentURL: string = "";
  extensions: Array<IExtension>= new Array<IExtension>;
  extSuppPack: Array<IExtSuppPack> = new Array<IExtSuppPack>;
  extensionIdAll: number = 0;
  items: Array<ISupportPackage> = new Array<ISupportPackage>;
  allItems: Array<ISupportPackage> = new Array<ISupportPackage>;
  selectedComponent: string = "";
  selectedExtensionId: number;
  title: string = "Support Packages";

  constructor(
    private router: Router,
    private route: ActivatedRoute
    ) {
    this.selectedExtensionId = this.extensionIdAll;
  }

  // Initialisierung
  ngOnInit(): void {
    // URL-Parameter ermitteln
    let params = this.route.snapshot.queryParams;
    let param = "";

    // Werte aus Parametern übernehmen
    param = "ext_id";
    if (params[param]) {
      this.selectedExtensionId = Number(params[param]);
    }

    param = "comp";
    if (params[param]) {
      this.selectedComponent = params[param];
    }

    // Daten übernehmen
    this.allItems = this.route.snapshot.data['supportpackages'];
    this.extensions = this.route.snapshot.data['extensions'];
    this.extSuppPack = this.route.snapshot.data['extsupppack'];

    // Anzuzeigende Elemente setzen
    this.setItemsToDisplay();
  }

  // Ermittelt die anzuzeigenden Elemente
  getItemsToDisplay(): Array<ISupportPackage> {
     let items = this.allItems.filter(item => {
      if (this.selectedExtensionId) {
        let extSuppPack = this.extSuppPack.find(esp => {
          return esp.supportPackageId == item.id && esp.extensionId == this.selectedExtensionId;
        });

        if (!extSuppPack){
          return false;
        }        
      }

      if (this.selectedComponent) {
          if (this.selectedComponent == 'sourcecode' && item.sourcecode){
            return true;
          }

          if (this.selectedComponent == 'premium_support' && item.premiumSupport){
            return true;
          }

          if (this.selectedComponent == 'both' && item.sourcecode && item.premiumSupport){
            return true;
          }

          return false;
      }

      return true;
    });

    return items;
  }

  // Selektierter Paketbestandteil hat sich geändert
  onSelectedComponentChange(value: string) {
    this.selectedComponent = value;
    this.updateURL();
    this.setItemsToDisplay();
  }

  // Selektierte Erweiterung hat sich geändert
  onSelectedExtensionChange(value: number) {
    this.selectedExtensionId = value;
    this.updateURL();
    this.setItemsToDisplay();
  }

  // Setzt die anzuzeigenden Elemente
  setItemsToDisplay() {
    this.items = this.getItemsToDisplay();
  }

  // URL aktualisieren
  updateURL() {
    let params: any = {};

    if (this.selectedExtensionId) {
      params["ext_id"] = this.selectedExtensionId;
    }

    if (this.selectedComponent){
      params["comp"] = this.selectedComponent;
    }

    const navExtras: NavigationExtras = {
      relativeTo: this.route,
      queryParams: params
    };

    const url = this.router.createUrlTree([], navExtras);
    this.router.navigateByUrl(url, { replaceUrl: true });
  }
}
