export interface IChangePassword{
  password: string;
  passwordRepeat: string;
}

export function emptyChangePassword(): IChangePassword{
  return {
    password: "",
    passwordRepeat: ""
  };
}