<mat-card appearance="outlined">
  <mat-card-header>
      <mat-card-title>{{download.name}}</mat-card-title>
      <mat-card-subtitle>File size: {{ download.fileSize }}</mat-card-subtitle>
  </mat-card-header>
  
  <mat-card-actions>
      <a mat-stroked-button [href]="getURL()" color="primary">
          <mat-icon>
              download
          </mat-icon>
          Download
      </a>
  </mat-card-actions>
</mat-card>