// Import Angular
import { Component } from '@angular/core';
import { Router } from '@angular/router';

// Import Schnittstellen
import { IResetPassword, emptyResetPassword } from '../_interface/reset-password-intercace';

// Import Klassen
import { App } from '../_class/app';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss'
})
export class ResetPasswordComponent {
  resetPassword: IResetPassword = emptyResetPassword();

  // Konstruktor
  constructor(public app: App, private router: Router) {
  }

  async reset(){
    let ret = await this.app.resetPassword(this.resetPassword);

    if (ret.ok) {
      if (ret.msg) {
        this.app.showInfo(ret.msg);
      }
    } else {
      if (ret.msg) {
        this.app.showError(ret.msg);
      }
    }
  }
}
