import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IExtension } from '../_interface/extension-interface';
import { IUser } from '../_interface/user-interface';
import { Observable } from 'rxjs';
import { App } from '../_class/app';

@Component({
  selector: 'app-extensions',
  templateUrl: './extensions.component.html',
  styleUrls: ['./extensions.component.scss']
})
export class ExtensionsComponent implements OnInit {
  extensions: Array<IExtension>;
  extensions$: Observable<Array<IExtension>>;

  constructor(private app: App, private route: ActivatedRoute ) {
    this.extensions = new Array<IExtension>
    this.extensions$ = new Observable<Array<IExtension>>;
  }

  ngOnInit(): void {
    this.extensions = this.route.snapshot.data['extensions'];
  }
}