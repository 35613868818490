<div class="main-content">
  <div class="top">
    <mat-card class="top-card" appearance="outlined">
      <mat-card-header class="back-color-secondary">
        <mat-card-title>
          <mat-icon>{{titleIcon}}</mat-icon>
          <span style="padding-left: 12px">{{titleText}}</span>
        </mat-card-title>
      </mat-card-header>
    </mat-card>
  </div>

  <div class="main">
    <form *ngIf="!showPlaceholder" #form="ngForm" (ngSubmit)="save()">
      <div class="section-container">
        <div class="field-container-vert">
          <mat-form-field appearance="fill" subscriptSizing="dynamic" [hideRequiredMarker]="!!changePassword.password">
            <mat-label>New password</mat-label>
            <input required matInput [(ngModel)]="changePassword.password" name="password" type="password"
              autocomplete="current-password">
          </mat-form-field>

          <mat-form-field appearance="fill" subscriptSizing="dynamic"
            [hideRequiredMarker]="!!changePassword.passwordRepeat">
            <mat-label>Repeat new password</mat-label>
            <input required matInput [(ngModel)]="changePassword.passwordRepeat" name="password-repeat" type="password"
              autocomplete="current-password">
          </mat-form-field>
        </div>

        <div class="container-horz-hcenter-vcenter">
          <button mat-stroked-button color="primary" class="button-save" type="submit">
            <mat-icon>{{saveIcon}}</mat-icon>
            {{saveText}}
          </button>
        </div>
      </div>
    </form>

    <div *ngIf="showPlaceholder" class="field-container-vert">
      <div class="placeholder-text">{{placeholderText}}</div>
    </div>
  </div>
</div>