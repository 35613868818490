<div class="main-content">
  <div class="top">
    <mat-card class="top-card" appearance="outlined">
      <mat-card-header class="back-color-secondary">
        <mat-card-title>
          <mat-icon>{{titleIcon}}</mat-icon>
          <span style="padding-left: 12px">{{titleText}}</span>
        </mat-card-title>
      </mat-card-header>
    </mat-card>
  </div>

  <div class="main">
    <div *ngIf="!showPlaceholder" class="section-container">
      <form *ngIf="!showPlaceholder" #form="ngForm" (ngSubmit)="save()">
        <div class="field-container-vert">
          <mat-form-field *ngIf="showUserName" appearance="fill" subscriptSizing="dynamic"
            [hideRequiredMarker]="!!user.name">
            <mat-label>User name</mat-label>
            <input required matInput [(ngModel)]="user.name" name="username" autocomplete="username">
          </mat-form-field>

          <mat-form-field *ngIf="showEmail" appearance="fill" subscriptSizing="dynamic"
            [hideRequiredMarker]="!!user.email">
            <mat-label>E-mail</mat-label>
            <input required email matInput [(ngModel)]="user.email" type="email" name="email" autocomplete="email">
          </mat-form-field>

          <mat-form-field *ngIf="showPassword" appearance="fill" subscriptSizing="dynamic"
            [hideRequiredMarker]="!!user.password">
            <mat-label>Password</mat-label>
            <input required matInput [(ngModel)]="user.password" type="password" name="password"
              autocomplete="current-password">
          </mat-form-field>

          <mat-form-field *ngIf="showPassword" appearance="fill" subscriptSizing="dynamic"
            [hideRequiredMarker]="!!user.passwordRepeat">
            <mat-label>Repeat password</mat-label>
            <input required matInput [(ngModel)]="user.passwordRepeat" type="password" name="password-repeat"
              autocomplete="current-password">
          </mat-form-field>
        </div>

        <div class="field-container-vert">
          <mat-form-field *ngIf="showFirstName" appearance="fill" subscriptSizing="dynamic"
            [hideRequiredMarker]="!!user.firstName">
            <mat-label>First name</mat-label>
            <input required matInput [(ngModel)]="user.firstName" name="first-name" autocomplete="given-name">
          </mat-form-field>

          <mat-form-field *ngIf="showLastName" appearance="fill" subscriptSizing="dynamic"
            [hideRequiredMarker]="!!user.lastName">
            <mat-label>Last name</mat-label>
            <input required matInput [(ngModel)]="user.lastName" name="last-name" autocomplete="family-name">
          </mat-form-field>

          <mat-form-field *ngIf="showCompany" appearance="fill" subscriptSizing="dynamic">
            <mat-label>Company</mat-label>
            <input matInput [(ngModel)]="user.company" name="company" autocomplete="organization">
          </mat-form-field>

          <div class="container-horz-hcenter-vcenter">
            <button mat-stroked-button color="primary" class="button-save" type="submit">
              <mat-icon>{{saveIcon}}</mat-icon>
              {{saveText}}
            </button>
          </div>
        </div>
      </form>

      <div *ngIf="showSupportPackages" class="card-container">
        <mat-card appearance="outlined">
          <mat-card-header>
            <mat-card-title>
              <mat-icon>support_agent</mat-icon>
              <span style="padding-left: 12px">My support packages</span>
            </mat-card-title>
          </mat-card-header>
        </mat-card>

        <div class="card-container">
          <div *ngFor="let item of supportPackages">
            <mat-card appearance="outlined">
              <mat-card-header>
                <mat-card-title>{{item.name}}</mat-card-title>
              </mat-card-header>

              <mat-card-content>
                <div *ngIf="item.warningText" class="warning">
                  <mat-icon>warning</mat-icon>
                  <div class="warning-text">{{item.warningText}}</div>
                </div>

                <div>
                  <span>Type: {{item.type}}</span>
                </div>

                <div *ngIf="isSubscription(item)">
                  <span>Term: {{item.termBegin}} - {{item.termEnd}}</span>
                </div>

                <div *ngIf="isOneTime(item)">
                  <span>For version: {{item.oneTimeVersion}}</span>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="showPlaceholder" class="field-container-vert">
      <div class="placeholder-text">{{placeholderText}}</div>

      <div *ngIf="showPlaceholderLoginBtn" class="container-horz-hcenter-vcenter">
        <button mat-stroked-button color="primary" class="button-save" [routerLink]="'/login'">
          <mat-icon>login</mat-icon>
          Login
        </button>
      </div>
    </div>
  </div>
</div>