import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { App } from '../_class/app';

import { IUser, ISaveUser, emptyUser } from '../_interface/user-interface';
import { IUserSupportPackage } from '../_interface/user-support-package-interface';

@Component({
  selector: 'app-user-account',
  templateUrl: './user-account.component.html',
  styleUrl: './user-account.component.scss'
})
export class UserAccountComponent {
  mode: string = "";
  user: IUser = emptyUser();
  supportPackages: Array<IUserSupportPackage> = new Array<IUserSupportPackage>;

  get accountMode(): boolean {
    return this.mode == 'user-account';
  }

  get placeholderText(): string {
    let ret = "";

    if (this.registerMode) {
      ret = 'You are already registered';
    } else {
      ret = 'You are not logged in';
    }

    return ret;
  }

  get registerMode(): boolean {
    return this.mode == 'register';
  }

  get saveIcon(): string {
    let ret = "";

    if (this.registerMode) {
      ret = 'person_add';
    } else {
      ret = 'save';
    }

    return ret;
  }

  get saveText(): string {
    let ret = "";

    if (this.registerMode) {
      ret = 'Register';
    } else {
      ret = 'Save changes';
    }

    return ret;
  }

  get showCompany(): boolean {
    return true;
  }

  get showEmail(): boolean {
    return true;
  }

  get showFirstName(): boolean {
    return true;
  }

  get showLastName(): boolean {
    return true;
  }

  get showPassword(): boolean {
    return !this.accountMode;
  }

  get showPlaceholder(): boolean {
    let show = false;

    if (this.registerMode) {
      if (this.app.accessToken) {
        show = true;
      }
    } else {
      if (!this.app.accessToken) {
        show = true;
      }
    }

    return show;
  }

  get showPlaceholderLoginBtn(): boolean {
    return !this.registerMode;
  }

  get showSupportPackages(): boolean {
    return this.accountMode && this.supportPackages.length > 0;
  }

  get showUserName(): boolean {
    return true;
  }

  get titleIcon(): string {
    let ret = "";

    if (this.registerMode) {
      ret = 'person_add';
    } else {
      ret = 'manage_accounts';
    }

    return ret;
  }

  get titleText(): string {
    let ret = "";

    if (this.registerMode) {
      ret = 'Register';
    } else {
      ret = 'My account';
    }

    return ret;
  }

  constructor(public app: App, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    // URL ermitteln
    let url = this.route.snapshot.url;

    // Modus
    this.mode = url[0].path;

    // Daten übernehmen
    if (!this.registerMode) {
      this.user = this.route.snapshot.data['loggedinuser'];
      this.supportPackages = this.route.snapshot.data['supportpackages'];
    }
  }

  isOneTime(usp: IUserSupportPackage) {
    let bIs = false;

    if (usp.type == App.SUPPORT_PACKAGE_TYPE_ONE_TIME) {
      bIs = true;
    }

    return bIs;
  }

  isSubscription(usp: IUserSupportPackage) {
    let bIs = false;

    if (usp.type == App.SUPPORT_PACKAGE_TYPE_SUBSCRIPTION) {
      bIs = true;
    }

    return bIs;
  }

  async save() {
    let saveMode = "";

    if (this.registerMode) {
      saveMode = 'new';
    } else {
      saveMode = 'modify';
    }

    let saveUser: ISaveUser = {
      user: this.user,
      mode: saveMode
    }

    // Speichern
    let ret = await this.app.saveUser(saveUser);

    if (ret.ok) {
      if (ret.msg) {
        this.app.showInfo(ret.msg);

        if (this.registerMode) {
          this.app.goBack();
        }
      }
    } else {
      if (ret.msg) {
        this.app.showError(ret.msg);
      }
    }
  }
}