import { Component } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { IDownload } from '../_interface/download-interface';
import { IExtension } from '../_interface/extension-interface';
import { App } from '../_class/app';
import { IListItem, IListItemNum } from '../_interface/list-item-interface';
import { ITdVersion } from '../_interface/td-version-interface';

@Component({
  selector: 'app-downloads',
  templateUrl: './downloads.component.html',
  styleUrl: './downloads.component.scss'
})
export class DownloadsComponent {
  currentURL: string = "";
  extensions: Array<IExtension> = new Array<IExtension>;
  items: Array<IDownload> = new Array<IDownload>;
  allItems: Array<IDownload> = new Array<IDownload>;
  platforms: Array<IListItem> = [{value: "Win32", text: "Win32"}, {value: "x64", text: "x64"}];
  selectedExtensionId: number = 0;
  selectedPlatform: string = "";
  selectedTdVersion: number = 0;
  tdVersions: Array<ITdVersion> = new Array<ITdVersion>;
  title: string = "Support Packages";

  constructor(
    private app: App,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  // Initialisierung
  ngOnInit(): void {
    // URL-Parameter ermitteln
    let params = this.route.snapshot.queryParams;
    let param = "";

    // Werte aus Parametern übernehmen
    param = "ext_id";
    if (params[param]) {
      this.selectedExtensionId = Number(params[param]);
    }

    param = "td_ver_id";
    if (params[param]) {
      this.selectedTdVersion = Number(params[param]);
    }

    param = "platform";
    if (params[param]) {
      this.selectedPlatform = params[param];
    }

    // Daten übernehmen
    this.allItems = this.route.snapshot.data['downloads'];
    this.extensions = this.route.snapshot.data['extensions'];
    this.tdVersions = this.route.snapshot.data['tdversions'];

    // Anzuzeigende Elemente setzen
    this.setItemsToDisplay();
  }

  // Ermittelt die anzuzeigenden Elemente 
  getItemsToDisplay(): Array<IDownload> {
    let items = this.allItems.filter(item => {
      if (this.selectedExtensionId && item.extensionId != this.selectedExtensionId) {
        return false;
      }

      if (this.selectedTdVersion && item.type == App.DOWNLOAD_TYPE_INSTALL && item.tdVersionId != this.selectedTdVersion) {
        return false;
      }

      if (this.selectedPlatform && item.type == App.DOWNLOAD_TYPE_INSTALL && item.platform != this.selectedPlatform) {
        return false;
      }

      return true;
    });

    return items;
  }

  // Selektierte Erweiterung hat sich geändert
  onSelectedExtensionChange(value: number) {
    this.selectedExtensionId = value;
    this.updateURL();
    this.setItemsToDisplay();
  }

  onSelectedPlatformChange(value: string) {
    this.selectedPlatform = value;
    this.updateURL();
    this.setItemsToDisplay();
  }

  // Selektierte TD-Version hat sich geändert
  onSelectedTdVersionChange(value: number) {
    this.selectedTdVersion = value;
    this.updateURL();
    this.setItemsToDisplay();
  }

  // Setzt die anzuzeigenden Elemente
  setItemsToDisplay() {
    this.items = this.getItemsToDisplay();
  }

  // URL aktualisieren
  updateURL() {
    let params: any = {};

    if (this.selectedExtensionId) {
      params["ext_id"] = this.selectedExtensionId;
    }

    if (this.selectedTdVersion) {
      params["td_ver_id"] = this.selectedTdVersion;
    }

    if (this.selectedPlatform) {
      params["platform"] = this.selectedPlatform
    }

    const navExtras: NavigationExtras = {
      relativeTo: this.route,
      queryParams: params
    };

    const url = this.router.createUrlTree([], navExtras);
    this.router.navigateByUrl(url, { replaceUrl: true });
  }
}
