// Import Angular
import { Component } from '@angular/core';

// Import Klassen
import { App } from '../_class/app';

@Component({
  selector: 'app-login',
  standalone: false,
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {
  name: string = "";
  password: string = "";

  // Konstruktor
  constructor(public app: App) {
  }

  canLogin() {
    if (!this.name) {
      return false;
    }

    if (!this.password) {
      return false;
    }

    return true;
  }

  async login() {
    if (this.canLogin()) {
      let ret = await this.app.login(this.name, this.password);

      if (ret.ok) {
        this.app.goBack();
      } else {
        this.app.showError(ret.msg);
      }
    }
  }
}
