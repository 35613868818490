// Import Angular
import { Injectable, inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ActivatedRouteSnapshot, NavigationEnd, ResolveFn, Router, RouterStateSnapshot } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

// Import rxjs
import { map, Observable } from 'rxjs';

// Import Schnittstellen
import { IDownload } from '../_interface/download-interface';
import { IExtension } from '../_interface/extension-interface';
import { IExtSuppPack } from '../_interface/ext_supp_pack-interface';
import { ISupportPackage } from '../_interface/support-package-interface';
import { ITdVersion } from '../_interface/td-version-interface';
import { IApiReturn } from '../_interface/api-return-interface';
import { IUser, ISaveUser, emptyUser } from '../_interface/user-interface';
import { IUserSupportPackage } from '../_interface/user-support-package-interface';
import { IChangePassword } from '../_interface/change-password-interface';
import { IResetPassword } from '../_interface/reset-password-intercace';

// Import Dienste
import { ApiService } from '../_service/api.service';

@Injectable()
export class App {
  static DATE_FORMAT_DB = "%Y/%m/%d";

  static DOWNLOAD_TYPE_SOURCECODE = "Sourcecode";
  static DOWNLOAD_TYPE_INSTALL = "Installation";

  static SUPPORT_PACKAGE_TYPE_SUBSCRIPTION = "Subscription";
  static SUPPORT_PACKAGE_TYPE_ONE_TIME = "One-time";

  public accessToken = "";
  private currentUrl = "";
  public loggedInUserName = "";
  private previousUrl = "";

  // Konstruktor
  constructor(
    private apiSvc: ApiService,
    private snackBar: MatSnackBar,
    private router: Router
  ) {
    let accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      this.accessToken = accessToken;
    }

    let loggedInUserName = localStorage.getItem('loggedInUserName');
    if (loggedInUserName) {
      this.loggedInUserName = loggedInUserName;
    }

    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {      
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      };
    });
  }

  // Passwort ändern
  changePassword(token: string, changePassword: IChangePassword): Promise<IApiReturn> {
    return new Promise((resolve, reject) => {
      try {
        this.apiSvc.changePassword(token, changePassword).subscribe(apiRet => {
          resolve(apiRet);
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  // Ermittelt Downloads
  getDownloads$(): Observable<Array<IDownload>> {
    return this.apiSvc.getDownloads(this.accessToken)
      .pipe(map(apiRet => {
        let ret = new Array<IDownload>;

        if (apiRet.ok) {
          ret = apiRet.data;
        } else {
          throw Error(apiRet.msg);
        }

        return ret;
      })
      );
  }

  // Zurück
  goBack(){
    let url = "";
    if (this.previousUrl){
      let usePreviousUrl = true;

      let dontGoBackTo = ['reset-password', 'mail-validation'];
      dontGoBackTo.forEach(item => {
        if (this.previousUrl.includes(item)){
          usePreviousUrl = false;
        }
      });
      
      if (usePreviousUrl){
        url = this.previousUrl;
      }      
    }

    if (!url.length){
      url = "/";
    }

    this.router.navigateByUrl(url);
  }

  // Ermittelt Erweiterungen
  getExtensions$(): Observable<Array<IExtension>> {
    // Basis-Select
    let sql = `SELECT ext.*,    
    concat('${environment.manualUrl}', ext.documentationPath, lower(ext.internalName), '.htm' ) AS documentationURL
    FROM extensions AS ext
    ORDER BY ext.displayPosition`;

    let select = {
      rowsAsArray: false,
      sql: sql
    };

    return this.apiSvc.select(select)
      .pipe(map(apiRet => {
        let ret = new Array<IExtension>;

        if (apiRet.ok) {
          ret = apiRet.data;
        } else {
          throw Error(apiRet.msg);
        }

        return ret;
      })
      );
  }

  // Ermittelt die Zuordnung von Support-Paketen zu Erweiterungen
  getExtSuppPack$(): Observable<Array<IExtSuppPack>> {
    // Basis-Select
    let sql = "SELECT * FROM extSuppPack ORDER BY extensionId, supportPackageId";

    let select = {
      rowsAsArray: false,
      sql: sql
    };

    return this.apiSvc.select(select)
      .pipe(map(apiRet => {
        let ret = new Array<IExtSuppPack>;

        if (apiRet.ok) {
          ret = apiRet.data;
        } else {
          throw Error(apiRet.msg);
        }

        return ret;
      })
      );
  }

  // Ermittelt den angemeldeten Benutzer
  getLoggedInUser$(): Observable<IUser> {
    return this.apiSvc.getLoggedInUser(this.accessToken)
      .pipe(map(apiRet => {
        let ret: IUser = emptyUser();

        if (apiRet.ok) {
          ret = apiRet.data[0];
        } else {
          throw Error(apiRet.msg);
        }

        return ret;
      })
      );
  }

  // Ermittelt Support-Pakete
  getSupportPackages$(): Observable<Array<ISupportPackage>> {
    let sql = "SELECT * FROM supportPackages ORDER BY price DESC";

    let select = {
      rowsAsArray: false,
      sql: sql
    }

    return this.apiSvc.select(select)
      .pipe(map(apiRet => {
        let ret = new Array<ISupportPackage>;

        if (apiRet.ok) {
          ret = apiRet.data;
        } else {
          throw Error(apiRet.msg);
        }

        return ret;
      })
      );
  }

  // Liefert Team Developer Versionen
  getTdVersions$(): Observable<Array<ITdVersion>> {
    let sql = "SELECT * FROM tdVersions ORDER BY version DESC";

    let select = {
      rowsAsArray: false,
      sql: sql
    };

    return this.apiSvc.select(select)
      .pipe(map(apiRet => {
        let ret = new Array<ITdVersion>;

        if (apiRet.ok) {
          ret = apiRet.data;
        } else {
          throw Error(apiRet.msg);
        }

        return ret;
      })
      );
  }

  // Ermittelt die Support-Pakete des aktuellen Benutzers
  getUserSupportPackages$(): Observable<Array<IUserSupportPackage>> {
    // Basis-Select
    let sql = `SELECT DISTINCT sp.name,
    sp.type,
    DATE_FORMAT(usp.termBegin, '${App.DATE_FORMAT_DB}') AS 'termBegin',
    DATE_FORMAT(usp.termEnd, '${App.DATE_FORMAT_DB}') AS 'termEnd',
    usp.oneTimeVersion,
    CASE
      WHEN sp.type = '${App.SUPPORT_PACKAGE_TYPE_SUBSCRIPTION}' THEN
        CASE
          WHEN usp.termBegin > sysdate() THEN 'The subscription has not started yet'
          WHEN usp.termEnd < sysdate() THEN 'The subscription has expired'
          ELSE ''
        END
      WHEN sp.type = '${App.SUPPORT_PACKAGE_TYPE_ONE_TIME}' AND usp.oneTimeVersion != ex.currentVersion THEN CONCAT(usp.oneTimeVersion, ' is no longer the current version')
      ELSE ''
    END AS 'warningText'
    FROM userSuppPack AS usp
    JOIN supportPackages AS sp ON sp.id = usp.supportPackageId
    JOIN extSuppPack AS esp ON esp.supportPackageId = sp.id
    JOIN extensions AS ex ON ex.id = esp.extensionId
    JOIN accessTokens AS at ON at.userId = usp.userId
    WHERE at.token = '${this.accessToken}'`;

    let select = {
      rowsAsArray: false,
      sql: sql
    };

    return this.apiSvc.select(select)
      .pipe(map(apiRet => {
        let ret = new Array<IUserSupportPackage>;

        if (apiRet.ok) {
          ret = apiRet.data;
        } else {
          throw Error(apiRet.msg);
        }

        return ret;
      })
      );
  }

  // Login
  login(user: string, password: string): Promise<IApiReturn> {
    return new Promise((resolve, reject) => {
      try {
        this.apiSvc.login(user, password).subscribe(apiRet => {
          if (apiRet.ok) {
            this.accessToken = apiRet.data[0]?.access_token;
            localStorage.setItem('accessToken', this.accessToken);

            this.loggedInUserName = user;
            localStorage.setItem('loggedInUserName', this.loggedInUserName);
          }          
          resolve(apiRet);
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  // Logout
  logout(): Promise<IApiReturn> {
    return new Promise((resolve, reject) => {
      try {
        this.apiSvc.logout(this.accessToken).subscribe(apiRet => {
          this.accessToken = "";
          localStorage.removeItem('accessToken');

          this.loggedInUserName = "";
          localStorage.removeItem('loggedInUserName');

          resolve(apiRet);
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  // Passwort zurücksetzen
  resetPassword(resetPassword: IResetPassword): Promise<IApiReturn> {
    return new Promise((resolve, reject) => {
      try {
        this.apiSvc.resetPassword(resetPassword).subscribe(apiRet => {
          resolve(apiRet);
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  // saveUser
  saveUser(saveUser: ISaveUser): Promise<IApiReturn> {
    return new Promise((resolve, reject) => {
      try {
        this.apiSvc.saveUser(saveUser).subscribe(apiRet => {
          resolve(apiRet);
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  // Fehler anzeigen
  showError(msg: string, duration?: number) {
    let options: MatSnackBarConfig = {};
    options.panelClass = "snackbar-error";

    if (duration){
      options.duration =  duration * 1000;
    }

    this.snackBar.open(msg, "Ok", options);
  }

  // Info anzeigen
  showInfo(msg: string, duration?: number) {    
    let options: MatSnackBarConfig = {};
    options.panelClass = "snackbar-info";

    if (duration){
      options.duration =  duration * 1000;
    }
    
    this.snackBar.open(msg, "Ok", options);
  }

  // Ermittelt Downloads
  validateMail$(token: string): Observable<IApiReturn> {
    return this.apiSvc.validateEmail(token)
      .pipe(map(apiRet => {
        return apiRet;
      })
      );
  }
}

// ***Resolver***

// Downloads
export const downloadsResolver: ResolveFn<Array<IDownload>> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(App).getDownloads$();
}

// Erweiterungen
export const extensionsResolver: ResolveFn<Array<IExtension>> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(App).getExtensions$();
}

// Zuordnung von Support-Paketen zu Erweiterungen
export const extSuppPackResolver: ResolveFn<Array<IExtSuppPack>> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(App).getExtSuppPack$();
}

// Angemeldeter Benutzer
export const loggedInUserResolver: ResolveFn<IUser> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(App).getLoggedInUser$();
}

// Support-Pakete
export const supportPackagesResolver: ResolveFn<Array<ISupportPackage>> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(App).getSupportPackages$();
}

// Liste der Team Developer Versionen
export const tdVersionsResolver: ResolveFn<Array<ITdVersion>> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(App).getTdVersions$();
}

// Support-Pakete des aktuellen Benutzers
export const userSupportPackagesResolver: ResolveFn<Array<IUserSupportPackage>> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(App).getUserSupportPackages$();
}

// E-Mail validieren
export const validateMailResolver: ResolveFn<IApiReturn> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  let token = "";
  if (route.queryParams['token']) {
    token = route.queryParams['token'];
  }
  return inject(App).validateMail$(token);
}