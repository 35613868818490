<div class = "legal-disclosure-container">
    <h1>Legal Disclosure</h1>
    <p>Information in accordance with Section 5 TMG</p>
    <p>
        Michael Stoll<br>
        Van-Endert-Weg 9<br>
        47445 Moers<br>
    </p>
    <h2>Contact Information</h2>
    <p>
        E-Mail: <a href="mailto:info@micsto.com">info&#64;micsto.com</a><br>
        Internet address: <a href="http://www.micsto.com" target="_blank">www.micsto.com</a>
    </p>
    
</div>