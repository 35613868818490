// Import Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

// Import Schnittstellen
import { IApiReturn } from '../_interface/api-return-interface';
import { ISaveUser } from '../_interface/user-interface';
import { IChangePassword } from '../_interface/change-password-interface';
import { IResetPassword } from '../_interface/reset-password-intercace';


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }

  changePassword(token: string, changePassword: IChangePassword): Observable<IApiReturn> {
    const url = environment.baseUrlApi + `?action=change_password&token=${token}`;

    let body = JSON.stringify(changePassword);
    return this.http.post<IApiReturn>(url, body);
  }

  getDownloads(token?: string): Observable<IApiReturn> {
    const url = environment.baseUrlApi + "?action=get_downloads" + (token ? `&token=${token}` : '');

    let body = '';
    if (token) {
      body = JSON.stringify({access_token: token});
    }
    
    return this.http.post<IApiReturn>(url, body);
  }

  getLoggedInUser(token?: string): Observable<IApiReturn> {
    const url = environment.baseUrlApi + "?action=get_logged_in_user" + (token ? `&token=${token}` : '');

    let body = '';
    return this.http.post<IApiReturn>(url, body);
  }

  login(user: string, password: string): Observable<IApiReturn> {
    const url = environment.baseUrlApi + "?action=login";

    let body = JSON.stringify({name: user, password: password});
    return this.http.post<IApiReturn>(url, body);
  }

  logout(token?: string): Observable<IApiReturn> {
    const url = environment.baseUrlApi + "?action=logout" + (token ? `&token=${token}` : '');

    let body = '';
    return this.http.post<IApiReturn>(url, body);
  }

  resetPassword(resetPassword: IResetPassword): Observable<IApiReturn> {
    const url = environment.baseUrlApi + `?action=reset_password`;

    let body = JSON.stringify(resetPassword);
    return this.http.post<IApiReturn>(url, body);
  }

  saveUser(saveUser: ISaveUser): Observable<IApiReturn> {
    const url = environment.baseUrlApi + "?action=save_user";

    let body = JSON.stringify(saveUser);
    return this.http.post<IApiReturn>(url, body);
  }

  select(select: any): Observable<IApiReturn> {
    const url = environment.baseUrlApi + "?action=sql&type=query";
    return this.http.post<IApiReturn>(url, select);
  }

  validateEmail(token?: string): Observable<IApiReturn> {
    const url = environment.baseUrlApi + "?action=validate_mail" + (token ? `&token=${token}` : '');

    let body = '';
    return this.http.post<IApiReturn>(url, body);
  }
}
