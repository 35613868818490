export interface IUser {
  id: number;
  name: string;
  email: string;
  password: string;
  passwordRepeat: string;
  firstName: string;
  lastName: string;
  company: string;
  emailValidated: string;
}

export interface ISaveUser {
  user: IUser;
  mode: string;
}

export function emptyUser(): IUser{
  return {
    id: 0,
    name: "",
    email: "",
    password: "",
    passwordRepeat: "",
    firstName: "",
    lastName: "",
    company: "",
    emailValidated: ""
  }
}