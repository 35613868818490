<div class="main-content">
  <div class="top">
      <mat-card class="top-card" appearance="outlined">
          <mat-card-header class="back-color-secondary">
              <mat-card-title>
                  <mat-icon>mail</mat-icon>
                  <span style="padding-left: 12px">Email validation</span>
              </mat-card-title>
          </mat-card-header>
      </mat-card>
  </div>

  <div class="placeholder-text" [ngStyle]="{'color': validationResult.ok ? 'inherit' : 'crimson'}">
    {{validationResult.msg}}
  </div>
</div>
